import { Loader2, Trash2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { UseMutationResult } from "@tanstack/react-query";

interface HeroImageCardProps {
  id: number;
  imageUrl: string;
  altText: string;
  deleteImage: UseMutationResult<void, Error, number>;
}

export const HeroImageCard = ({ id, imageUrl, altText, deleteImage }: HeroImageCardProps) => {
  return (
    <div className="border rounded-lg p-4">
      <img
        src={imageUrl}
        alt={altText}
        className="w-full h-48 object-cover rounded-lg mb-2"
      />
      <p className="text-sm text-gray-600 mb-2">{altText}</p>
      <Button
        variant="destructive"
        size="sm"
        onClick={() => deleteImage.mutate(id)}
        disabled={deleteImage.isPending}
        className="w-full"
      >
        {deleteImage.isPending ? (
          <Loader2 className="w-4 h-4 animate-spin" />
        ) : (
          <Trash2 className="w-4 h-4" />
        )}
        <span className="ml-2">Delete</span>
      </Button>
    </div>
  );
};