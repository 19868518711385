import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import { useToast } from "./ui/use-toast";
import { Loader2, Plus, Trash2, Plane, Globe, MapPin, Map, Ship, Train, Compass, Hotel, Car, Bus, Luggage, Ticket, Calendar, Users, Umbrella, HeartHandshake, Trophy, Target, Shield, Star, Award, Clock, Wallet, Phone, MessageCircle, Camera, Utensils, Mountain, Palm, Sunrise } from "lucide-react";
import * as LucideIcons from "lucide-react";

export const ServicesManager = () => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [newService, setNewService] = useState({
    title: "",
    description: "",
    icon: "Plane",
    imageUrl: "",
  });
  const [uploading, setUploading] = useState(false);

  const { data: services = [], isLoading } = useQuery({
    queryKey: ["services"],
    queryFn: async () => {
      console.log("Fetching services...");
      const { data, error } = await supabase
        .from("services")
        .select("*")
        .order("created_at", { ascending: true });
      
      if (error) {
        console.error("Error fetching services:", error);
        throw error;
      }
      console.log("Services fetched:", data);
      return data;
    },
  });

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = event.target.files?.[0];
      if (!file) return;

      setUploading(true);
      console.log("Starting image upload...");

      // First, check if the file is an image
      if (!file.type.startsWith('image/')) {
        throw new Error('Please upload an image file');
      }

      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random()}.${fileExt}`;

      // Upload the file to Supabase Storage
      const { error: uploadError, data } = await supabase.storage
        .from('service-images')
        .upload(fileName, file);

      if (uploadError) {
        console.error("Storage upload error:", uploadError);
        throw uploadError;
      }

      console.log("File uploaded successfully, getting public URL...");

      // Get the public URL
      const { data: { publicUrl } } = supabase.storage
        .from('service-images')
        .getPublicUrl(fileName);

      console.log("Image public URL:", publicUrl);
      setNewService({ ...newService, imageUrl: publicUrl });
      toast({
        title: "Success",
        description: "Image uploaded successfully",
      });
    } catch (error: any) {
      console.error("Image upload error:", error);
      toast({
        title: "Error",
        description: "Failed to upload image: " + error.message,
        variant: "destructive",
      });
    } finally {
      setUploading(false);
    }
  };

  const addService = useMutation({
    mutationFn: async (service: typeof newService) => {
      console.log("Adding new service:", service);
      const { error } = await supabase
        .from("services")
        .insert([{
          title: service.title,
          description: service.description,
          icon: service.icon,
          image_url: service.imageUrl,
        }]);
      
      if (error) {
        console.error("Error adding service:", error);
        throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["services"] });
      setNewService({ title: "", description: "", icon: "Anchor", imageUrl: "" });
      toast({
        title: "Success",
        description: "Service added successfully",
      });
    },
    onError: (error: any) => {
      console.error("Mutation error:", error);
      toast({
        title: "Error",
        description: "Failed to add service: " + error.message,
        variant: "destructive",
      });
    },
  });

  const deleteService = useMutation({
    mutationFn: async (id: number) => {
      console.log("Deleting service:", id);
      const { error } = await supabase
        .from("services")
        .delete()
        .eq("id", id);
      
      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["services"] });
      toast({
        title: "Success",
        description: "Service deleted successfully",
      });
    },
    onError: (error: any) => {
      console.error("Delete error:", error);
      toast({
        title: "Error",
        description: "Failed to delete service: " + error.message,
        variant: "destructive",
      });
    },
  });

  const iconNames = [
    "Plane",      // For flights
    "Globe",      // For international travel
    "MapPin",     // For destinations
    "Map",        // For travel planning
    "Ship",       // For cruises
    "Train",      // For rail travel
    "Compass",    // For adventures/tours
    "Hotel",      // For accommodations
    "Car",        // For car rentals
    "Bus",        // For ground transportation
    "Luggage",    // For baggage services
    "Ticket",     // For bookings
    "Calendar",   // For scheduling
    "Users",      // For group tours
    "Umbrella",   // For protection/insurance
    "HeartHandshake", // For customer service
    "Trophy",     // For excellence
    "Target",     // For goals
    "Shield",     // For safety
    "Star",       // For quality
    "Award",      // For certifications
    "Clock",      // For timing
    "Wallet",     // For payments
    "Phone",      // For support
    "MessageCircle", // For communication
    "Camera",     // For photography
    "Utensils",   // For food tours
    "Mountain",   // For adventures
    "Palm",       // For beach destinations
    "Sunrise",    // For experiences
  ];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white p-6 rounded-lg shadow mb-8">
        <h3 className="text-xl font-semibold mb-4">Add New Service</h3>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">Title</label>
            <Input
              value={newService.title}
              onChange={(e) =>
                setNewService({ ...newService, title: e.target.value })
              }
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Description</label>
            <Textarea
              value={newService.description}
              onChange={(e) =>
                setNewService({ ...newService, description: e.target.value })
              }
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Icon</label>
            <select
              className="w-full border rounded-md p-2"
              value={newService.icon}
              onChange={(e) =>
                setNewService({ ...newService, icon: e.target.value })
              }
            >
              {iconNames.map((name) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Image</label>
            <Input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              disabled={uploading}
            />
            {newService.imageUrl && (
              <img
                src={newService.imageUrl}
                alt="Service preview"
                className="mt-2 w-32 h-32 object-cover rounded"
              />
            )}
          </div>
          <Button
            onClick={() => addService.mutate(newService)}
            disabled={addService.isPending || !newService.title || !newService.description}
          >
            {addService.isPending ? (
              <Loader2 className="w-4 h-4 animate-spin mr-2" />
            ) : (
              <Plus className="w-4 h-4 mr-2" />
            )}
            Add Service
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {services.map((service) => {
          const IconComponent = LucideIcons[service.icon as keyof typeof LucideIcons] as React.FC<any>;
          
          return (
            <div key={service.id} className="bg-white p-6 rounded-lg shadow">
              <div className="flex justify-between items-start">
                <div className="flex-1">
                  <div className="text-ocean-primary mb-4">
                    {IconComponent && (
                      <IconComponent 
                        className="w-8 h-8 animate-icon-bounce hover:animate-icon-pulse" 
                      />
                    )}
                  </div>
                  {service.image_url && (
                    <div className="relative group">
                      <img
                        src={service.image_url}
                        alt={service.title}
                        className="w-full h-48 object-cover rounded mb-4"
                      />
                      <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-20 transition-opacity duration-300 rounded" />
                    </div>
                  )}
                  <h3 className="text-lg font-semibold mb-2">{service.title}</h3>
                  <p className="text-gray-600">{service.description}</p>
                </div>
                <Button
                  variant="destructive"
                  size="icon"
                  onClick={() => deleteService.mutate(service.id)}
                  disabled={deleteService.isPending}
                >
                  <Trash2 className="w-4 h-4" />
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
