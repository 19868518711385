import { cn } from "@/lib/utils";
import { CompanyInfo } from "./types";

interface HeroContentProps {
  companyInfo: CompanyInfo | null;
  isScrolled: boolean;
}

export const HeroContent = ({ companyInfo, isScrolled }: HeroContentProps) => {
  return (
    <div className="absolute inset-0 flex flex-col items-center justify-center text-white">
      {companyInfo?.logo_url && (
        <div className={cn(
          "mb-8 transition-all duration-700 transform",
          isScrolled ? "opacity-0 scale-50" : "opacity-100 scale-100 animate-float"
        )}>
          <div className="w-64 h-64 bg-white/10 rounded-full flex items-center justify-center backdrop-blur-md shadow-2xl border border-shell-primary/20">
            <div className="w-48 h-48 bg-gradient-to-b from-shell-light to-white rounded-full flex items-center justify-center p-8">
              <img 
                src={companyInfo.logo_url} 
                alt={companyInfo.name}
                className="w-full h-full object-contain drop-shadow-xl"
                style={{ filter: 'brightness(0.95) contrast(1.1)' }}
              />
            </div>
          </div>
        </div>
      )}
      <div className={cn(
        "text-center transition-all duration-700 transform",
        isScrolled ? "opacity-0 translate-y-10" : "opacity-100 translate-y-0"
      )}>
        <h1 
          className="text-6xl md:text-8xl font-bold mb-6 animate-fade-up 
            drop-shadow-[0_4px_8px_rgba(0,0,0,0.3)] 
            text-shell-light hover:text-white 
            transition-all duration-500 transform hover:scale-105"
        >
          {companyInfo?.name || "Shell & Sands"}
        </h1>
        <p 
          className="text-xl md:text-3xl mb-8 
            animate-fade-up delay-100 
            drop-shadow-[0_2px_4px_rgba(0,0,0,0.25)] 
            text-shell-secondary hover:text-white
            transition-all duration-500 transform hover:scale-102"
        >
          {companyInfo?.slogan || "Discover Paradise Your Way"}
        </p>
      </div>
    </div>
  );
};