import { useState } from 'react';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Textarea } from './ui/textarea';
import { toast } from 'sonner';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { supabase } from '@/lib/supabase';

interface Package {
  id: number;
  name: string;
  price: string;
  features: string[];
  image_url?: string;
  pricing_type: string;
}

export function PackageManager() {
  const queryClient = useQueryClient();
  const [newPackage, setNewPackage] = useState({
    name: '',
    price: '',
    features: '',
    imageUrl: '',
    pricingType: 'per person',
  });
  const [uploading, setUploading] = useState(false);

  const { data: packages = [], isLoading } = useQuery({
    queryKey: ['packages'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('packages')
        .select('*')
        .order('id', { ascending: true });

      if (error) throw error;
      return data || [];
    },
  });

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = event.target.files?.[0];
      if (!file) return;

      setUploading(true);
      console.log('Starting file upload:', { fileName: file.name, fileSize: file.size });

      // Validate file type
      if (!file.type.startsWith('image/')) {
        throw new Error('Please upload an image file');
      }

      // Validate file size (5MB limit)
      if (file.size > 5 * 1024 * 1024) {
        throw new Error('File size must be less than 5MB');
      }

      const fileExt = file.name.split('.').pop()?.toLowerCase() || '';
      const fileName = `${Date.now()}.${fileExt}`;
      
      const { error: uploadError, data } = await supabase.storage
        .from('package-images')
        .upload(fileName, file, {
          cacheControl: '3600',
          upsert: false,
          contentType: file.type
        });

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from('package-images')
        .getPublicUrl(fileName);

      setNewPackage(prev => ({ ...prev, imageUrl: publicUrl }));
      toast.success('Image uploaded successfully');
    } catch (error: any) {
      console.error('Upload error:', error);
      toast.error(error.message || 'Failed to upload image');
    } finally {
      setUploading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const { error } = await supabase
        .from('packages')
        .insert([{
          name: newPackage.name,
          price: newPackage.price,
          features: newPackage.features.split('\n').filter(Boolean),
          image_url: newPackage.imageUrl,
          pricing_type: newPackage.pricingType,
        }]);

      if (error) throw error;

      toast.success('Package created successfully');
      setNewPackage({
        name: '',
        price: '',
        features: '',
        imageUrl: '',
        pricingType: 'per person'
      });
      queryClient.invalidateQueries({ queryKey: ['packages'] });
    } catch (error: any) {
      console.error('Error creating package:', error);
      toast.error(error.message || 'Error creating package');
    }
  };

  const handleDelete = async (id: number) => {
    try {
      const { error } = await supabase
        .from('packages')
        .delete()
        .eq('id', id);

      if (error) throw error;

      toast.success('Package deleted successfully');
      queryClient.invalidateQueries({ queryKey: ['packages'] });
    } catch (error: any) {
      console.error('Error deleting package:', error);
      toast.error(error.message || 'Error deleting package');
    }
  };

  if (isLoading) {
    return <div className="flex justify-center items-center p-8">Loading packages...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h2 className="text-2xl font-bold mb-6">Package Management</h2>
      
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Add New Package</h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">Package Name</label>
            <Input
              value={newPackage.name}
              onChange={(e) => setNewPackage({ ...newPackage, name: e.target.value })}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Price</label>
            <Input
              value={newPackage.price}
              onChange={(e) => setNewPackage({ ...newPackage, price: e.target.value })}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Pricing Type</label>
            <Input
              value={newPackage.pricingType}
              onChange={(e) => setNewPackage({ ...newPackage, pricingType: e.target.value })}
              placeholder="e.g., per person, per group"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Features (one per line)</label>
            <Textarea
              value={newPackage.features}
              onChange={(e) => setNewPackage({ ...newPackage, features: e.target.value })}
              required
              rows={5}
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Package Image</label>
            <Input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              disabled={uploading}
            />
            {newPackage.imageUrl && (
              <img
                src={newPackage.imageUrl}
                alt="Package preview"
                className="mt-2 w-32 h-32 object-cover rounded"
              />
            )}
          </div>
          <Button type="submit" disabled={uploading}>Add Package</Button>
        </form>
      </div>

      <div>
        <h3 className="text-xl font-semibold mb-4">Existing Packages</h3>
        <div className="space-y-4">
          {packages.map((pkg) => (
            <div key={pkg.id} className="p-4 border rounded-lg">
              <div className="flex justify-between items-start">
                <div>
                  <h4 className="text-lg font-medium">{pkg.name}</h4>
                  <p className="text-gray-600">${pkg.price} / {pkg.pricing_type}</p>
                  {pkg.image_url && (
                    <img
                      src={pkg.image_url}
                      alt={pkg.name}
                      className="mt-2 w-32 h-32 object-cover rounded"
                    />
                  )}
                  <ul className="list-disc list-inside mt-2">
                    {pkg.features.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))}
                  </ul>
                </div>
                <Button
                  variant="destructive"
                  onClick={() => handleDelete(pkg.id)}
                >
                  Delete
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}