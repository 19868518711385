import { cn } from "@/lib/utils";
import { CompanyInfo } from "./types";
import { SocialMediaLinks } from "../SocialMediaLinks";

interface HeroHeaderProps {
  companyInfo: CompanyInfo | null;
  isScrolled: boolean;
}

export const HeroHeader = ({ companyInfo, isScrolled }: HeroHeaderProps) => {
  return (
    <div className={cn(
      "fixed top-0 left-0 right-0 z-50 transition-all duration-300",
      isScrolled ? "bg-shell-dark/90 backdrop-blur-sm" : "bg-transparent pointer-events-none"
    )}>
      <div className="container mx-auto px-4 py-2 flex items-center justify-between">
        <div className="flex items-center gap-4">
          {companyInfo?.logo_url && (
            <div className={cn(
              "transition-all duration-300",
              isScrolled ? "opacity-100 scale-100" : "opacity-0 scale-50 -translate-y-10"
            )}>
              <div className="w-12 h-12 bg-gradient-to-b from-shell-light to-white rounded-full p-2">
                <img 
                  src={companyInfo.logo_url} 
                  alt={companyInfo.name}
                  className="w-full h-full object-contain"
                  style={{ filter: 'brightness(0.95) contrast(1.1)' }}
                />
              </div>
            </div>
          )}
          <div className={cn(
            "transition-all duration-300",
            isScrolled ? "opacity-100 translate-x-0" : "opacity-0 -translate-x-10"
          )}>
            <h2 className="text-shell-light text-xl font-semibold">{companyInfo?.name}</h2>
            <p className="text-shell-secondary text-sm">{companyInfo?.slogan}</p>
          </div>
        </div>
        <div className={cn(
          "transition-all duration-300 pointer-events-auto",
          isScrolled ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-10"
        )}>
          <SocialMediaLinks iconColor="#F5EFE6" iconSize={20} showPhoneNumbers={true} />
        </div>
      </div>
    </div>
  );
};