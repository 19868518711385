import { ChevronDown } from "lucide-react";
import { Button } from "../ui/button";

interface HeroExploreButtonProps {
  onExplore: () => void;
}

export const HeroExploreButton = ({ onExplore }: HeroExploreButtonProps) => {
  return (
    <div className="absolute bottom-20 left-1/2 -translate-x-1/2 z-10 flex flex-col items-center gap-4">
      <span className="text-white text-lg font-medium tracking-wider drop-shadow-lg">
        Explore More
      </span>
      <Button
        onClick={onExplore}
        variant="ghost"
        size="icon"
        className="text-white hover:text-white/80 hover:bg-white/10 backdrop-blur-sm rounded-full p-3 animate-bounce shadow-lg"
      >
        <ChevronDown className="w-8 h-8" />
      </Button>
    </div>
  );
};