import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

interface HeroUploadFormProps {
  onUpload: (file: File, altText: string) => Promise<void>;
  uploading: boolean;
}

export const HeroUploadForm = ({ onUpload, uploading }: HeroUploadFormProps) => {
  const [altText, setAltText] = useState("");

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && altText) {
      await onUpload(file, altText);
      setAltText("");
      event.target.value = "";
    }
  };

  return (
    <div className="mb-8 p-4 border rounded-lg">
      <h3 className="text-lg font-semibold mb-4">Upload New Image</h3>
      <div className="space-y-4">
        <div>
          <Label htmlFor="alt-text">Alt Text</Label>
          <Input
            id="alt-text"
            value={altText}
            onChange={(e) => setAltText(e.target.value)}
            placeholder="Describe the image for accessibility"
            className="mt-1"
          />
        </div>
        <div>
          <Label htmlFor="image">Select Image</Label>
          <Input
            id="image"
            type="file"
            accept="image/*"
            onChange={handleFileUpload}
            disabled={uploading}
            className="mt-1"
          />
        </div>
      </div>
    </div>
  );
};