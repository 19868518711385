import { HeroImageCard } from "../HeroImageCard";
import { UseMutationResult } from "@tanstack/react-query";
import { Loader2 } from "lucide-react";

interface HeroImage {
  id: number;
  image_url: string;
  alt_text: string;
}

interface HeroImageGridProps {
  images: HeroImage[];
  isLoading: boolean;
  deleteImage: UseMutationResult<void, Error, number>;
}

export const HeroImageGrid = ({ images, isLoading, deleteImage }: HeroImageGridProps) => {
  if (isLoading) {
    return (
      <div className="p-6 flex justify-center items-center">
        <Loader2 className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  if (images.length === 0) {
    return <p className="text-center text-gray-500 mt-8">No images uploaded yet</p>;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
      {images.map((image) => (
        <HeroImageCard
          key={image.id}
          id={image.id}
          imageUrl={image.image_url}
          altText={image.alt_text}
          deleteImage={deleteImage}
        />
      ))}
    </div>
  );
};