import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import { toast } from "sonner";
import { Loader2, Pencil, Trash2 } from "lucide-react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";

interface WhyChooseUsItem {
  id: number;
  title: string;
  description: string;
  icon: string;
  image_url: string | null;
}

export const WhyChooseUsManager = () => {
  const queryClient = useQueryClient();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedItem, setSelectedItem] = useState<WhyChooseUsItem | null>(null);
  const [newItem, setNewItem] = useState({
    title: "",
    description: "",
    icon: "shield",
    imageUrl: "",
  });
  const [uploading, setUploading] = useState(false);

  const { data: items = [], isLoading } = useQuery({
    queryKey: ["whyChooseUs-admin"],
    queryFn: async () => {
      console.log("Fetching why choose us items for admin...");
      const { data, error } = await supabase
        .from("why_choose_us")
        .select("*")
        .order("id", { ascending: true });

      if (error) throw error;
      return data;
    },
  });

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = event.target.files?.[0];
      if (!file) return;

      setUploading(true);
      console.log("Starting image upload...");

      if (!file.type.startsWith("image/")) {
        throw new Error("Please upload an image file");
      }

      const fileExt = file.name.split(".").pop();
      const fileName = `${Math.random()}.${fileExt}`;

      const { error: uploadError } = await supabase.storage
        .from("why-choose-us-images")
        .upload(fileName, file);

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from("why-choose-us-images")
        .getPublicUrl(fileName);

      if (isEditing && selectedItem) {
        setSelectedItem({ ...selectedItem, image_url: publicUrl });
      } else {
        setNewItem({ ...newItem, imageUrl: publicUrl });
      }

      toast.success("Image uploaded successfully");
    } catch (error: any) {
      console.error("Image upload error:", error);
      toast.error(error.message || "Failed to upload image");
    } finally {
      setUploading(false);
    }
  };

  const addItem = useMutation({
    mutationFn: async () => {
      const { error } = await supabase
        .from("why_choose_us")
        .insert([{
          title: newItem.title,
          description: newItem.description,
          icon: newItem.icon,
          image_url: newItem.imageUrl,
        }]);

      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["whyChooseUs-admin"] });
      setNewItem({ title: "", description: "", icon: "shield", imageUrl: "" });
      toast.success("Item added successfully");
    },
    onError: (error: any) => {
      toast.error(error.message || "Failed to add item");
    },
  });

  const updateItem = useMutation({
    mutationFn: async (item: WhyChooseUsItem) => {
      const { error } = await supabase
        .from("why_choose_us")
        .update({
          title: item.title,
          description: item.description,
          icon: item.icon,
          image_url: item.image_url,
        })
        .eq("id", item.id);

      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["whyChooseUs-admin"] });
      setSelectedItem(null);
      setIsEditing(false);
      toast.success("Item updated successfully");
    },
    onError: (error: any) => {
      toast.error(error.message || "Failed to update item");
    },
  });

  const deleteItem = useMutation({
    mutationFn: async (id: number) => {
      const { error } = await supabase
        .from("why_choose_us")
        .delete()
        .eq("id", id);

      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["whyChooseUs-admin"] });
      toast.success("Item deleted successfully");
    },
    onError: (error: any) => {
      toast.error(error.message || "Failed to delete item");
    },
  });

  const iconOptions = [
    "shield",
    "heart",
    "award",
    "star",
    "target",
    "trophy",
    "users",
    "compass",
    "globe",
    "phone",
  ];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white p-6 rounded-lg shadow mb-8">
        <h3 className="text-xl font-semibold mb-4">
          {isEditing ? "Edit Item" : "Add New Item"}
        </h3>
        <Tabs defaultValue="content" className="w-full">
          <TabsList className="mb-4">
            <TabsTrigger value="content">Content</TabsTrigger>
            <TabsTrigger value="preview">Preview</TabsTrigger>
          </TabsList>
          <TabsContent value="content">
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium mb-1">Title</label>
                <Input
                  value={isEditing ? selectedItem?.title : newItem.title}
                  onChange={(e) =>
                    isEditing
                      ? setSelectedItem(prev => ({ ...prev!, title: e.target.value }))
                      : setNewItem({ ...newItem, title: e.target.value })
                  }
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Description (Tile View)</label>
                <Textarea
                  value={isEditing ? selectedItem?.description.split('\n')[0] : newItem.description.split('\n')[0]}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (isEditing) {
                      const fullDesc = selectedItem?.description.split('\n');
                      fullDesc![0] = value;
                      setSelectedItem(prev => ({ ...prev!, description: fullDesc!.join('\n') }));
                    } else {
                      const fullDesc = newItem.description.split('\n');
                      fullDesc[0] = value;
                      setNewItem({ ...newItem, description: fullDesc.join('\n') });
                    }
                  }}
                  placeholder="Short description for the tile view"
                  className="mb-4"
                />
                <label className="block text-sm font-medium mb-1">Full Description</label>
                <Textarea
                  value={isEditing ? selectedItem?.description : newItem.description}
                  onChange={(e) =>
                    isEditing
                      ? setSelectedItem(prev => ({ ...prev!, description: e.target.value }))
                      : setNewItem({ ...newItem, description: e.target.value })
                  }
                  placeholder="Use # for headings, * for bullet points, ** for bold **, and _ for italic _"
                  rows={8}
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Icon</label>
                <Select
                  value={isEditing ? selectedItem?.icon : newItem.icon}
                  onValueChange={(value) =>
                    isEditing
                      ? setSelectedItem(prev => ({ ...prev!, icon: value }))
                      : setNewItem({ ...newItem, icon: value })
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select icon" />
                  </SelectTrigger>
                  <SelectContent>
                    {iconOptions.map((icon) => (
                      <SelectItem key={icon} value={icon}>
                        {icon}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Image</label>
                <Input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  disabled={uploading}
                />
                {((isEditing && selectedItem?.image_url) || (!isEditing && newItem.imageUrl)) && (
                  <img
                    src={isEditing ? selectedItem?.image_url! : newItem.imageUrl}
                    alt="Preview"
                    className="mt-2 w-32 h-32 object-cover rounded"
                  />
                )}
              </div>
              <Button
                onClick={() => {
                  if (isEditing && selectedItem) {
                    updateItem.mutate(selectedItem);
                  } else {
                    addItem.mutate();
                  }
                }}
                disabled={uploading || addItem.isPending || updateItem.isPending}
              >
                {(addItem.isPending || updateItem.isPending) ? (
                  <Loader2 className="w-4 h-4 animate-spin mr-2" />
                ) : null}
                {isEditing ? "Update" : "Add"} Item
              </Button>
              {isEditing && (
                <Button
                  variant="outline"
                  onClick={() => {
                    setIsEditing(false);
                    setSelectedItem(null);
                  }}
                  className="ml-2"
                >
                  Cancel
                </Button>
              )}
            </div>
          </TabsContent>
          <TabsContent value="preview">
            <div className="prose max-w-none">
              <h3>{isEditing ? selectedItem?.title : newItem.title}</h3>
              <div>
                {(isEditing ? selectedItem?.description : newItem.description)
                  .split('\n')
                  .map((line, index) => {
                    if (line.startsWith('# ')) {
                      return <h4 key={index}>{line.slice(2)}</h4>;
                    } else if (line.startsWith('* ')) {
                      return <li key={index}>{line.slice(2)}</li>;
                    } else if (line.startsWith('**') && line.endsWith('**')) {
                      return <strong key={index}>{line.slice(2, -2)}</strong>;
                    } else if (line.startsWith('_') && line.endsWith('_')) {
                      return <em key={index}>{line.slice(1, -1)}</em>;
                    }
                    return <p key={index}>{line}</p>;
                  })}
              </div>
            </div>
          </TabsContent>
        </Tabs>
      </div>

      <div className="bg-white rounded-lg shadow">
        <h3 className="text-xl font-semibold p-6 border-b">Manage Items</h3>
        <div className="divide-y">
          {items.map((item) => (
            <div key={item.id} className="p-6 flex items-center justify-between">
              <div>
                <h4 className="font-medium">{item.title}</h4>
                <p className="text-sm text-gray-500 line-clamp-2">{item.description}</p>
              </div>
              <div className="flex items-center gap-2">
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => {
                    setSelectedItem(item);
                    setIsEditing(true);
                  }}
                >
                  <Pencil className="w-4 h-4" />
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => deleteItem.mutate(item.id)}
                >
                  <Trash2 className="w-4 h-4 text-red-500" />
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
