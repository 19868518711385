import { useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import { Upload } from "lucide-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { Button } from "./ui/button";
import { HeroUploadForm } from "./HeroUploadForm";
import { HeroImageGrid } from "./hero-manager/HeroImageGrid";

interface HeroImage {
  id: number;
  image_url: string;
  alt_text: string;
}

export const HeroManager = () => {
  const [uploading, setUploading] = useState(false);
  const [showUploadForm, setShowUploadForm] = useState(false);
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const { data: images = [], isLoading } = useQuery({
    queryKey: ["hero-images"],
    queryFn: async () => {
      console.log("Fetching hero images...");
      const { data, error } = await supabase
        .from('hero_images')
        .select('*')
        .order('created_at', { ascending: false });
      
      if (error) {
        console.error("Error fetching hero images:", error);
        throw error;
      }
      
      console.log("Hero images fetched successfully:", data);
      return data as HeroImage[];
    },
  });

  const handleUpload = async (file: File, altText: string) => {
    try {
      console.log("Starting image upload...");
      setUploading(true);

      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random()}.${fileExt}`;

      const { error: uploadError } = await supabase.storage
        .from('hero-images')
        .upload(fileName, file);

      if (uploadError) {
        throw uploadError;
      }

      const { data: { publicUrl } } = supabase.storage
        .from('hero-images')
        .getPublicUrl(fileName);

      const { error: insertError } = await supabase
        .from('hero_images')
        .insert([
          {
            image_url: publicUrl,
            alt_text: altText,
          }
        ]);

      if (insertError) {
        throw insertError;
      }

      toast({
        title: "Success",
        description: "Image uploaded successfully",
      });
      
      setShowUploadForm(false);
      queryClient.invalidateQueries({ queryKey: ["hero-images"] });
    } catch (error: any) {
      console.error("Upload error:", error);
      toast({
        title: "Error",
        description: "Failed to upload image: " + error.message,
        variant: "destructive",
      });
    } finally {
      setUploading(false);
    }
  };

  const deleteImage = useMutation({
    mutationFn: async (id: number) => {
      const { error } = await supabase
        .from('hero_images')
        .delete()
        .eq('id', id);
      
      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["hero-images"] });
      toast({
        title: "Success",
        description: "Image deleted successfully",
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to delete image: " + error.message,
        variant: "destructive",
      });
    },
  });

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Manage Hero Images</h2>
        <Button
          onClick={() => setShowUploadForm(!showUploadForm)}
          className="flex items-center gap-2"
        >
          <Upload className="w-4 h-4" />
          {showUploadForm ? 'Cancel Upload' : 'Upload Image'}
        </Button>
      </div>
      
      {showUploadForm && (
        <HeroUploadForm onUpload={handleUpload} uploading={uploading} />
      )}

      <HeroImageGrid
        images={images}
        isLoading={isLoading}
        deleteImage={deleteImage}
      />
    </div>
  );
};