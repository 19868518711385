import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/lib/supabase';
import { Loader2, MapPin, Phone, Mail } from 'lucide-react';
import { MapLocation } from './MapLocation';

export const CompanyAddress = () => {
  const { data: address, isLoading } = useQuery({
    queryKey: ['company-address'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('company_addresses')
        .select('*')
        .eq('is_primary', true)
        .single();
      
      if (error) throw error;
      return data;
    }
  });

  if (isLoading) {
    return (
      <div className="bg-white rounded-lg shadow-xl">
        <div className="p-4">
          <Loader2 className="w-5 h-5 animate-spin mx-auto" />
        </div>
      </div>
    );
  }

  if (!address) {
    return null;
  }

  return (
    <div className="bg-white rounded-lg shadow-xl overflow-hidden h-full">
      <div className="h-[200px] relative">
        <MapLocation />
      </div>
      <div className="p-4">
        <h3 className="text-xl font-semibold mb-4 text-travel-dark">
          Visit Our Office
        </h3>
        <div className="space-y-3 text-sm">
          <div className="flex items-start gap-2 group">
            <MapPin className="w-4 h-4 mt-1 text-travel-primary group-hover:text-travel-accent transition-colors shrink-0" />
            <div>
              <p className="text-gray-700 font-medium">{address.street}</p>
              <p className="text-gray-600">{address.city}, {address.state} {address.postal_code}</p>
              <p className="text-gray-600">{address.country}</p>
            </div>
          </div>
          
          {address.phone && (
            <div className="flex items-center gap-2 group">
              <Phone className="w-4 h-4 text-travel-primary group-hover:text-travel-accent transition-colors shrink-0" />
              <a 
                href={`tel:${address.phone}`} 
                className="text-gray-700 hover:text-travel-primary transition-colors"
              >
                {address.phone}
              </a>
            </div>
          )}
          
          {address.email && (
            <div className="flex items-center gap-2 group">
              <Mail className="w-4 h-4 text-travel-primary group-hover:text-travel-accent transition-colors shrink-0" />
              <a 
                href={`mailto:${address.email}`} 
                className="text-gray-700 hover:text-travel-primary transition-colors"
              >
                {address.email}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};