import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ContactMessagesTable } from "./ContactMessagesTable";
import { QuoteRequestsTable } from "./QuoteRequestsTable";
import { supabase } from "@/lib/supabase";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Label } from "./ui/label";
import { toast } from "sonner";

export const MessageViewer = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState<string>("created_at");
  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [filterAttended, setFilterAttended] = useState<string>("all");
  const queryClient = useQueryClient();

  const { data: contactMessages = [], isLoading: loadingContacts } = useQuery({
    queryKey: ["contact-messages", sortField, sortOrder, filterAttended],
    queryFn: async () => {
      console.log('Fetching contact messages...');
      let query = supabase
        .from('contact_submissions')
        .select('*')
        .order(sortField, { ascending: sortOrder === 'asc' });

      if (filterAttended !== 'all') {
        query = query.eq('is_attended', filterAttended === 'attended');
      }

      const { data, error } = await query;

      if (error) {
        console.error('Error fetching contact messages:', error);
        throw error;
      }

      console.log('Contact messages fetched:', data);
      return data.filter(message =>
        message.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        message.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        message.message.toLowerCase().includes(searchTerm.toLowerCase())
      );
    },
  });

  const { data: quoteRequests = [], isLoading: loadingQuotes } = useQuery({
    queryKey: ["quote-requests", sortField, sortOrder, filterAttended],
    queryFn: async () => {
      console.log('Fetching quote requests...');
      let query = supabase
        .from('quote_requests')
        .select('*')
        .order(sortField, { ascending: sortOrder === 'asc' });

      if (filterAttended !== 'all') {
        query = query.eq('is_attended', filterAttended === 'attended');
      }

      const { data, error } = await query;

      if (error) {
        console.error('Error fetching quote requests:', error);
        throw error;
      }

      console.log('Quote requests fetched:', data);
      return data.filter(quote =>
        quote.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        quote.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        quote.message.toLowerCase().includes(searchTerm.toLowerCase()) ||
        quote.package_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    },
  });

  const toggleAttendedMutation = useMutation({
    mutationFn: async ({ id, type, isAttended }: { id: number; type: 'contact' | 'quote'; isAttended: boolean }) => {
      console.log('Toggling attended status:', { id, type, isAttended });
      const table = type === 'contact' ? 'contact_submissions' : 'quote_requests';
      const { error } = await supabase
        .from(table)
        .update({ is_attended: isAttended })
        .eq('id', id);

      if (error) {
        console.error('Error updating status:', error);
        throw error;
      }
      console.log('Status updated successfully');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["contact-messages"] });
      queryClient.invalidateQueries({ queryKey: ["quote-requests"] });
      toast.success("Status updated successfully");
    },
    onError: (error) => {
      console.error('Error updating attended status:', error);
      toast.error("Failed to update status");
    },
  });

  if (loadingContacts || loadingQuotes) {
    return <div>Loading messages...</div>;
  }

  return (
    <div className="space-y-6">
      <div className="flex flex-col gap-4 md:flex-row md:items-end">
        <div className="flex-1">
          <Label htmlFor="search">Search</Label>
          <Input
            id="search"
            placeholder="Search messages..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        
        <div className="w-full md:w-48">
          <Label htmlFor="sort-field">Sort By</Label>
          <Select value={sortField} onValueChange={setSortField}>
            <SelectTrigger id="sort-field">
              <SelectValue placeholder="Sort by..." />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="created_at">Date</SelectItem>
              <SelectItem value="name">Name</SelectItem>
              <SelectItem value="email">Email</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="w-full md:w-48">
          <Label htmlFor="sort-order">Order</Label>
          <Select value={sortOrder} onValueChange={setSortOrder}>
            <SelectTrigger id="sort-order">
              <SelectValue placeholder="Order..." />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="desc">Newest First</SelectItem>
              <SelectItem value="asc">Oldest First</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="w-full md:w-48">
          <Label htmlFor="filter-attended">Status</Label>
          <Select value={filterAttended} onValueChange={setFilterAttended}>
            <SelectTrigger id="filter-attended">
              <SelectValue placeholder="Filter by status..." />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">All Messages</SelectItem>
              <SelectItem value="attended">Attended</SelectItem>
              <SelectItem value="unattended">Unattended</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      <Tabs defaultValue="contacts">
        <TabsList>
          <TabsTrigger value="contacts">Contact Messages</TabsTrigger>
          <TabsTrigger value="quotes">Quote Requests</TabsTrigger>
        </TabsList>

        <TabsContent value="contacts">
          <ContactMessagesTable 
            messages={contactMessages} 
            onToggleAttended={(id, isAttended) => 
              toggleAttendedMutation.mutate({ id, type: 'contact', isAttended })}
          />
        </TabsContent>

        <TabsContent value="quotes">
          <QuoteRequestsTable 
            requests={quoteRequests} 
            onToggleAttended={(id, isAttended) => 
              toggleAttendedMutation.mutate({ id, type: 'quote', isAttended })}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
};