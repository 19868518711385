import ContactForm from "@/components/ContactForm";
import { CompanyAddress } from "./CompanyAddress";

const Contact = () => {
  return (
    <section className="py-20 bg-gradient-to-b from-white to-travel-light" id="contact">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-travel-dark mb-4">
              Get in Touch
            </h2>
            <p className="text-travel-dark/70 max-w-2xl mx-auto">
              We'd love to hear from you! Visit our office or send us a message.
            </p>
          </div>
          
          <div className="grid lg:grid-cols-2 gap-8">
            {/* Left column - Map and Address */}
            <div>
              <CompanyAddress />
            </div>
            
            {/* Right column - Contact Form */}
            <div>
              <div className="bg-white rounded-lg shadow-xl p-8">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;