import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/lib/supabase';
import { ReviewForm } from './ReviewForm';
import { Star, PenLine, MessagesSquare } from 'lucide-react';
import { Loader2 } from 'lucide-react';

export function Reviews() {
  const { data: reviews, isLoading } = useQuery({
    queryKey: ['approved-reviews'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('reviews')
        .select('*')
        .eq('is_approved', true)
        .order('created_at', { ascending: false });
      
      if (error) throw error;
      return data;
    }
  });

  return (
    <section className="py-20 bg-gradient-to-b from-gray-50 to-white" id="reviews">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto">
          <div className="flex items-center justify-center gap-3 mb-4">
            <MessagesSquare className="w-8 h-8 text-travel-primary" />
            <h2 className="text-4xl font-bold text-center text-travel-dark">
              Guest Reviews
            </h2>
          </div>
          <p className="text-center text-gray-600 mb-16 max-w-2xl mx-auto">
            Read what our guests have to say about their experiences
          </p>
          
          <div className="grid md:grid-cols-2 gap-12">
            {/* Recent Reviews Section - Moved to first position */}
            <div className="space-y-6">
              <div className="flex items-center gap-3 mb-8">
                <Star className="w-6 h-6 text-travel-primary shrink-0" />
                <h3 className="text-2xl font-bold text-travel-dark">
                  What Our Guests Say
                </h3>
              </div>
              
              {isLoading ? (
                <div className="flex justify-center">
                  <Loader2 className="w-8 h-8 animate-spin" />
                </div>
              ) : reviews && reviews.length > 0 ? (
                <div className="space-y-6">
                  {reviews.map((review) => (
                    <div 
                      key={review.id} 
                      className="bg-white/90 backdrop-blur-sm border border-gray-100 p-6 rounded-xl transition-all duration-300 hover:shadow-lg animate-fade-up"
                    >
                      <div className="flex items-center gap-2 mb-2">
                        {[1, 2, 3, 4, 5].map((star) => (
                          <Star
                            key={star}
                            className={`w-5 h-5 ${
                              star <= review.rating 
                                ? 'fill-yellow-400 text-yellow-400' 
                                : 'text-gray-300'
                            }`}
                          />
                        ))}
                      </div>
                      <p className="text-gray-700 mb-4 italic">"{review.review_text}"</p>
                      <p className="font-semibold text-travel-dark">
                        - {review.customer_name}
                      </p>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center text-gray-500 py-8">
                  No reviews yet. Be the first to share your experience!
                </div>
              )}
            </div>

            {/* Share Experience Section - Moved to second position */}
            <div className="bg-travel-primary/5 p-8 rounded-xl border border-travel-primary/10">
              <div className="flex items-center gap-3 mb-6">
                <PenLine className="w-6 h-6 text-travel-primary shrink-0" />
                <h3 className="text-2xl font-bold text-travel-dark">
                  Share Your Story
                </h3>
              </div>
              <ReviewForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}