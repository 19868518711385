import { Check } from "lucide-react";
import { Button } from "./ui/button";
import { toast } from "sonner";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { QuoteForm, QuoteFormValues } from "./QuoteForm";

const Packages = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState("");
  
  const { data: packages = [], isLoading, error } = useQuery({
    queryKey: ['packages'],
    queryFn: async () => {
      console.log('Starting packages fetch...');
      
      const { data, error } = await supabase
        .from('packages')
        .select('*')
        .order('id', { ascending: true });

      if (error) {
        console.error('Error fetching packages:', error);
        throw error;
      }

      console.log(`Successfully fetched ${data?.length || 0} packages`);
      return data || [];
    },
  });

  useEffect(() => {
    if (error) {
      console.error("Packages error:", error);
    }
  }, [error]);

  const handleQuoteSubmit = async (values: QuoteFormValues) => {
    console.log("Submitting quote request:", values);
    try {
      const { error } = await supabase
        .from('quote_requests')
        .insert([values]);

      if (error) throw error;
      
      console.log("Quote request submitted successfully");
      toast.success("Quote request submitted successfully!");
      setDialogOpen(false);
    } catch (error) {
      console.error('Error submitting quote:', error);
      toast.error("Failed to submit quote request");
    }
  };

  if (isLoading) {
    return (
      <section className="py-20">
        <div className="container">
          <h2 className="text-4xl font-bold text-center mb-12 text-travel-dark">Our Packages</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[1, 2, 3].map((i) => (
              <div
                key={i}
                className="p-8 glass-card rounded-lg animate-pulse"
              >
                <div className="h-8 bg-gray-200 rounded w-3/4 mb-4"></div>
                <div className="h-6 bg-gray-200 rounded w-1/2 mb-6"></div>
                <div className="space-y-3 mb-8">
                  {[1, 2, 3].map((j) => (
                    <div key={j} className="h-4 bg-gray-200 rounded w-full"></div>
                  ))}
                </div>
                <div className="h-10 bg-gray-200 rounded"></div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-4 text-travel-dark">Our Packages</h2>
        <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto">
          Choose the perfect package for your next adventure
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {packages.map((pkg) => (
            <div
              key={pkg.id}
              className="bg-white rounded-xl shadow-lg overflow-hidden flex flex-col h-full"
            >
              {pkg.image_url && (
                <div 
                  className="h-48 bg-cover bg-center w-full"
                  style={{ backgroundImage: `url(${pkg.image_url})` }}
                />
              )}
              <div className="p-6 flex flex-col flex-grow">
                <h3 className="text-2xl font-bold text-travel-dark mb-4">{pkg.name}</h3>
                <div className="flex items-baseline mb-6">
                  <span className="text-4xl font-bold text-travel-primary">${pkg.price}</span>
                  <span className="text-sm text-gray-600 ml-2">/{pkg.pricing_type}</span>
                </div>
                <ul className="space-y-4 mb-6 flex-grow">
                  {pkg.features.map((feature, index) => (
                    <li key={index} className="flex items-start gap-3">
                      <Check className="text-travel-primary w-5 h-5 mt-1 flex-shrink-0" />
                      <span className="text-gray-600">{feature}</span>
                    </li>
                  ))}
                </ul>
                <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
                  <DialogTrigger asChild>
                    <Button 
                      className="w-full bg-shell-primary hover:bg-shell-accent text-white mt-4"
                      onClick={() => setSelectedPackage(pkg.name)}
                    >
                      Request Quote
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="bg-white">
                    <DialogHeader>
                      <DialogTitle>Request Quote for {pkg.name}</DialogTitle>
                    </DialogHeader>
                    <QuoteForm 
                      packageName={pkg.name} 
                      onSubmit={handleQuoteSubmit}
                      onClose={() => setDialogOpen(false)}
                    />
                  </DialogContent>
                </Dialog>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Packages;