import { useQuery } from "@tanstack/react-query";
import * as LucideIcons from "lucide-react";
import { LucideIcon } from "lucide-react";
import { supabase } from "@/lib/supabase";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "./ui/button";
import { QuoteForm } from "./QuoteForm";
import { toast } from "sonner";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "./ui/card";

interface Service {
  id: number;
  title: string;
  description: string;
  icon: string;
  image_url?: string;
  created_at: string;
}

const Services = () => {
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [quoteDialogOpen, setQuoteDialogOpen] = useState(false);

  const { data: services = [], isLoading, error } = useQuery({
    queryKey: ["services"],
    queryFn: async () => {
      console.log("Fetching services...");
      const { data, error } = await supabase
        .from('services')
        .select('*')
        .order('created_at', { ascending: true });
      
      if (error) {
        console.error("Error fetching services:", error);
        throw error;
      }
      
      console.log("Services fetched successfully:", data?.length || 0);
      return data as Service[];
    },
  });

  useEffect(() => {
    if (error) {
      console.error("Service loading error:", error);
    }
  }, [error]);

  const handleQuoteSubmit = async (values: any) => {
    try {
      const { error } = await supabase
        .from('quote_requests')
        .insert([{
          ...values,
          package_name: selectedService?.title
        }]);

      if (error) throw error;

      toast.success("Quote request submitted successfully!");
      setQuoteDialogOpen(false);
      setDialogOpen(false);
    } catch (error) {
      console.error('Error submitting quote:', error);
      toast.error("Failed to submit quote request");
    }
  };

  const formatDescription = (description: string) => {
    const lines = description.split('\n');
    return lines.map((line, index) => {
      if (line.startsWith('# ')) {
        return (
          <h3 key={index} className="text-xl font-bold mt-4 mb-2">
            {line.replace('# ', '')}
          </h3>
        );
      } else if (line.startsWith('- ')) {
        return (
          <li key={index} className="ml-4">
            {line.replace('- ', '')}
          </li>
        );
      } else if (line.startsWith('$ ')) {
        return (
          <p key={index} className="text-xl font-bold text-travel-primary mt-2">
            {line.replace('$ ', '')}
          </p>
        );
      }
      return <p key={index} className="mb-2">{line}</p>;
    });
  };

  if (isLoading) {
    return (
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <div className="h-8 bg-gray-200 rounded animate-pulse w-1/3 mx-auto mb-12" />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[1, 2, 3, 4].map((i) => (
              <div key={i} className="p-6 glass-card rounded-lg">
                <div className="h-12 w-12 bg-gray-200 rounded animate-pulse mb-4" />
                <div className="h-6 bg-gray-200 rounded animate-pulse w-3/4 mb-2" />
                <div className="h-16 bg-gray-200 rounded animate-pulse" />
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12 text-travel-dark">Our Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {services.map((service) => {
            const IconComponent = (LucideIcons as any)[service.icon] as LucideIcon;
            
            return (
              <Dialog key={service.id} open={dialogOpen && selectedService?.id === service.id} onOpenChange={(open) => {
                setDialogOpen(open);
                if (!open) setSelectedService(null);
              }}>
                <DialogTrigger asChild>
                  <Card
                    className="transform hover:-translate-y-1 transition-all duration-300 cursor-pointer"
                    onClick={() => setSelectedService(service)}
                  >
                    <CardHeader>
                      <div className="text-travel-primary mb-4">
                        {IconComponent && <IconComponent className="w-12 h-12 mx-auto" />}
                      </div>
                      <CardTitle className="text-xl text-center">{service.title}</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <CardDescription className="text-center">
                        {service.description.split('\n')[0]}
                      </CardDescription>
                    </CardContent>
                  </Card>
                </DialogTrigger>
                <DialogContent className="sm:max-w-[600px]">
                  <DialogHeader>
                    <DialogTitle className="text-2xl font-bold flex items-center gap-2">
                      {IconComponent && <IconComponent className="w-6 h-6" />}
                      {service.title}
                    </DialogTitle>
                  </DialogHeader>
                  <div className="mt-4 space-y-4">
                    {service.image_url && (
                      <img
                        src={service.image_url}
                        alt={service.title}
                        className="w-full h-64 object-cover rounded-lg"
                      />
                    )}
                    <div className="prose prose-sm max-w-none">
                      {formatDescription(service.description)}
                    </div>
                    <Dialog open={quoteDialogOpen} onOpenChange={setQuoteDialogOpen}>
                      <DialogTrigger asChild>
                        <Button className="w-full mt-4">Request Quote</Button>
                      </DialogTrigger>
                      <DialogContent>
                        <DialogHeader>
                          <DialogTitle>Request Quote for {service.title}</DialogTitle>
                        </DialogHeader>
                        <QuoteForm
                          packageName={service.title}
                          onSubmit={handleQuoteSubmit}
                          onClose={() => setQuoteDialogOpen(false)}
                        />
                      </DialogContent>
                    </Dialog>
                  </div>
                </DialogContent>
              </Dialog>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Services;