import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Label } from "./ui/label";
import { toast } from "sonner";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";

export const EmailSettingsManager = () => {
  const [email, setEmail] = useState("");
  const queryClient = useQueryClient();

  const { data: settings, isLoading } = useQuery({
    queryKey: ["email-settings"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('email_settings')
        .select('*')
        .limit(1)
        .single();

      if (error) throw error;
      return data;
    },
    meta: {
      onSuccess: (data: any) => {
        if (data) {
          setEmail(data.forward_to_email);
        }
      }
    }
  });

  const updateEmailMutation = useMutation({
    mutationFn: async (newEmail: string) => {
      if (settings) {
        const { error } = await supabase
          .from('email_settings')
          .update({ forward_to_email: newEmail })
          .eq('id', settings.id);
        if (error) throw error;
      } else {
        const { error } = await supabase
          .from('email_settings')
          .insert([{ forward_to_email: newEmail }]);
        if (error) throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["email-settings"] });
      toast.success("Email settings updated successfully");
    },
    onError: (error) => {
      console.error('Error updating email settings:', error);
      toast.error("Failed to update email settings");
    },
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    updateEmailMutation.mutate(email);
  };

  if (isLoading) {
    return <div>Loading settings...</div>;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Email Forwarding Settings</CardTitle>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <Label htmlFor="forward-email">Forward Messages To</Label>
            <Input
              id="forward-email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email address"
              required
            />
          </div>
          <Button 
            type="submit" 
            disabled={updateEmailMutation.isPending}
          >
            {updateEmailMutation.isPending ? "Saving..." : "Save Settings"}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};