import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";

const About = () => {
  const { data: aboutContent, isLoading } = useQuery({
    queryKey: ["about-content"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("about_content")
        .select("*")
        .single();
      
      if (error) throw error;
      return data;
    },
  });

  if (isLoading) {
    return (
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <div className="h-64 bg-gray-200 rounded-lg animate-pulse" />
            <div className="space-y-4">
              <div className="h-8 bg-gray-200 rounded animate-pulse w-3/4" />
              <div className="h-24 bg-gray-200 rounded animate-pulse" />
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="py-20 bg-white relative z-20">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <div className="animate-fade-up">
            {aboutContent?.video_url ? (
              <div className="rounded-lg shadow-xl overflow-hidden hover:shadow-2xl transition-shadow duration-300">
                <video
                  src={aboutContent.video_url}
                  controls
                  className="w-full h-auto"
                  poster={aboutContent.image_url}
                >
                  Your browser does not support the video tag.
                </video>
              </div>
            ) : (
              <img
                src={aboutContent?.image_url}
                alt="About Shell & Sands"
                className="rounded-lg shadow-xl hover:shadow-2xl transition-shadow duration-300"
              />
            )}
          </div>
          <div className="animate-fade-up" style={{ animationDelay: "150ms" }}>
            <h2 className="text-4xl font-bold mb-8 text-travel-dark">
              {aboutContent?.title}
            </h2>
            {aboutContent?.description.map((paragraph, index) => (
              <p
                key={index}
                className="text-gray-600 mb-6 leading-relaxed"
                style={{ animationDelay: `${(index + 2) * 150}ms` }}
              >
                {paragraph}
              </p>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;