import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Checkbox } from "@/components/ui/checkbox";

interface ContactMessage {
  id: number;
  name: string;
  email: string;
  message: string;
  created_at: string;
  is_attended: boolean;
}

interface ContactMessagesTableProps {
  messages: ContactMessage[];
  onToggleAttended: (id: number, isAttended: boolean) => void;
}

export const ContactMessagesTable = ({ messages, onToggleAttended }: ContactMessagesTableProps) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Date</TableHead>
          <TableHead>Name</TableHead>
          <TableHead>Email</TableHead>
          <TableHead>Message</TableHead>
          <TableHead>Status</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {messages.map((message) => (
          <TableRow key={message.id}>
            <TableCell>
              {new Date(message.created_at).toLocaleDateString()}
            </TableCell>
            <TableCell>{message.name}</TableCell>
            <TableCell>{message.email}</TableCell>
            <TableCell>{message.message}</TableCell>
            <TableCell>
              <div className="flex items-center space-x-2">
                <Checkbox
                  checked={message.is_attended}
                  onCheckedChange={(checked) => 
                    onToggleAttended(message.id, checked as boolean)
                  }
                />
                <span>{message.is_attended ? 'Attended' : 'Pending'}</span>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};