import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import { useToast } from "./ui/use-toast";
import { Loader2 } from "lucide-react";

export const AboutManager = () => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [uploading, setUploading] = useState(false);

  const { data: aboutContent, isLoading } = useQuery({
    queryKey: ["about-content"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("about_content")
        .select("*")
        .single();
      
      if (error) throw error;
      return data;
    },
  });

  const updateContent = useMutation({
    mutationFn: async (values: {
      title: string;
      description: string[];
      image_url: string;
      video_url?: string;
    }) => {
      const { error } = await supabase
        .from("about_content")
        .upsert({ ...values, id: aboutContent?.id || 1 });
      
      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["about-content"] });
      toast({
        title: "Success",
        description: "About content updated successfully",
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to update content: " + error.message,
        variant: "destructive",
      });
    },
  });

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>, type: 'image' | 'video') => {
    try {
      setUploading(true);
      const file = event.target.files?.[0];
      if (!file) return;

      const fileExt = file.name.split(".").pop();
      const fileName = `${crypto.randomUUID()}.${fileExt}`;

      const { error: uploadError } = await supabase.storage
        .from("hero-images")
        .upload(fileName, file);

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from("hero-images")
        .getPublicUrl(fileName);

      const updateData = type === 'image' 
        ? { ...aboutContent, image_url: publicUrl }
        : { ...aboutContent, video_url: publicUrl };

      updateContent.mutate(updateData);

      toast({
        title: "Success",
        description: `${type === 'image' ? 'Image' : 'Video'} uploaded successfully`,
      });
    } catch (error: any) {
      toast({
        title: "Error",
        description: `Failed to upload ${type}: ` + error.message,
        variant: "destructive",
      });
    } finally {
      setUploading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const title = formData.get("title") as string;
    const description = (formData.get("description") as string)
      .split("\n")
      .filter(Boolean);
    const video_url = formData.get("video_url") as string;

    updateContent.mutate({
      title,
      description,
      video_url,
      image_url: aboutContent?.image_url || "",
    });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow">
      <h2 className="text-2xl font-bold mb-6">Manage About Section</h2>
      
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium mb-2">Title</label>
          <Input
            name="title"
            defaultValue={aboutContent?.title}
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">
            Description (one paragraph per line)
          </label>
          <Textarea
            name="description"
            defaultValue={aboutContent?.description?.join("\n")}
            rows={6}
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">Video URL (optional)</label>
          <Input
            name="video_url"
            type="url"
            defaultValue={aboutContent?.video_url || ""}
            placeholder="Enter video URL"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">Image</label>
          {aboutContent?.image_url && (
            <img
              src={aboutContent.image_url}
              alt="About section"
              className="w-64 h-64 object-cover rounded mb-4"
            />
          )}
          <Input
            type="file"
            accept="image/*"
            onChange={(e) => handleFileUpload(e, 'image')}
            disabled={uploading}
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">Video File (optional)</label>
          {aboutContent?.video_url && (
            <video
              src={aboutContent.video_url}
              controls
              className="w-64 h-auto rounded mb-4"
            >
              Your browser does not support the video tag.
            </video>
          )}
          <Input
            type="file"
            accept="video/*"
            onChange={(e) => handleFileUpload(e, 'video')}
            disabled={uploading}
          />
        </div>

        <Button type="submit" disabled={updateContent.isPending || uploading}>
          {(updateContent.isPending || uploading) ? (
            <Loader2 className="w-4 h-4 animate-spin mr-2" />
          ) : null}
          Save Changes
        </Button>
      </form>
    </div>
  );
};