import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/lib/supabase';
import { Loader2 } from 'lucide-react';

export const MapLocation = () => {
  const { data: address, isLoading } = useQuery({
    queryKey: ['primary-address'],
    queryFn: async () => {
      console.log('Fetching primary address...');
      const { data, error } = await supabase
        .from('company_addresses')
        .select('*')
        .eq('is_primary', true)
        .single();

      if (error) {
        console.error('Error fetching address:', error);
        throw error;
      }

      return data;
    },
  });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-[200px]">
        <Loader2 className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  if (!address?.latitude || !address?.longitude) {
    return (
      <div className="flex justify-center items-center h-[200px] bg-gray-100 text-gray-500">
        No location coordinates available
      </div>
    );
  }

  // Create the Google Maps embed URL using coordinates
  const embedUrl = `https://www.google.com/maps/embed/v1/view?key=AIzaSyDHVHjHIpNnmQzYxb3obxz6SZ4pGK1Jc8s&center=${address.latitude},${address.longitude}&zoom=15`;

  return (
    <div className="w-full h-[200px] rounded-lg overflow-hidden">
      <iframe
        src={embedUrl}
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </div>
  );
};