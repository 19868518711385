import { SocialMediaLinks } from "./SocialMediaLinks";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";

export const Footer = () => {
  const { data: companyInfo } = useQuery({
    queryKey: ['company-info'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('company_info')
        .select('*')
        .single();
      
      if (error) throw error;
      return data;
    }
  });

  return (
    <footer className="bg-shell-dark text-shell-light py-12">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center gap-6">
          <SocialMediaLinks 
            iconSize={24} 
            iconColor="text-shell-secondary hover:text-shell-primary" 
            showPhoneNumbers={true} 
          />
          
          <p className="text-shell-light/60 text-sm">
            © {new Date().getFullYear()} {companyInfo?.name || "Shell & Sands"}. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};