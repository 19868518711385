import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import { Switch } from "./ui/switch";
import { useToast } from "@/hooks/use-toast";
import { Loader2, Trash2 } from "lucide-react";

const PLATFORMS = ["Facebook", "X", "Instagram", "LinkedIn", "Snapchat", "WhatsApp", "Viber", "YouTube"];

const PHONE_PLATFORMS = ["WhatsApp", "Viber"];

export const SocialMediaManager = () => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [newLink, setNewLink] = useState({
    platform: "",
    url: "",
    phoneNumber: "",
  });

  const { data: socialLinks, isLoading } = useQuery({
    queryKey: ["social-links-admin"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("social_media_links")
        .select("*")
        .order("created_at", { ascending: true });
      
      if (error) throw error;
      return data;
    }
  });

  const addLink = useMutation({
    mutationFn: async () => {
      const platformType = PHONE_PLATFORMS.includes(newLink.platform) ? "phone" : "link";
      const { error } = await supabase
        .from("social_media_links")
        .insert([{
          platform: newLink.platform,
          url: newLink.url,
          phone_number: PHONE_PLATFORMS.includes(newLink.platform) ? newLink.phoneNumber : null,
          platform_type: platformType,
          icon: newLink.platform.toLowerCase(),
        }]);
      
      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["social-links-admin"] });
      setNewLink({ platform: "", url: "", phoneNumber: "" });
      toast({
        title: "Success",
        description: "Social media link added successfully",
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to add social media link: " + error.message,
        variant: "destructive",
      });
    },
  });

  const updateStatus = useMutation({
    mutationFn: async ({ id, is_active }: { id: number; is_active: boolean }) => {
      const { error } = await supabase
        .from("social_media_links")
        .update({ is_active })
        .eq("id", id);
      
      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["social-links-admin"] });
      toast({
        title: "Success",
        description: "Status updated successfully",
      });
    },
  });

  const deleteLink = useMutation({
    mutationFn: async (id: number) => {
      const { error } = await supabase
        .from("social_media_links")
        .delete()
        .eq("id", id);
      
      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["social-links-admin"] });
      toast({
        title: "Success",
        description: "Social media link deleted successfully",
      });
    },
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const isPlatformWithPhone = PHONE_PLATFORMS.includes(newLink.platform);

  return (
    <div className="space-y-8">
      <div className="grid gap-4 p-6 bg-white rounded-lg shadow">
        <h2 className="text-xl font-semibold">Add New Social Media Link</h2>
        <div className="grid gap-4">
          <div className="grid gap-2">
            <label>Platform</label>
            <Select
              value={newLink.platform}
              onValueChange={(value) => setNewLink(prev => ({ ...prev, platform: value }))}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select platform" />
              </SelectTrigger>
              <SelectContent>
                {PLATFORMS.map((platform) => (
                  <SelectItem key={platform} value={platform}>
                    {platform}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          {isPlatformWithPhone && (
            <div className="grid gap-2">
              <label>Phone Number</label>
              <Input
                type="tel"
                value={newLink.phoneNumber}
                onChange={(e) => setNewLink(prev => ({ ...prev, phoneNumber: e.target.value }))}
                placeholder="+1234567890"
              />
            </div>
          )}
          <div className="grid gap-2">
            <label>{isPlatformWithPhone ? "Additional URL (optional)" : "URL"}</label>
            <Input
              type="url"
              value={newLink.url}
              onChange={(e) => setNewLink(prev => ({ ...prev, url: e.target.value }))}
              placeholder={isPlatformWithPhone ? "https://... (optional)" : "https://..."}
              required={!isPlatformWithPhone}
            />
          </div>
          <Button
            onClick={() => addLink.mutate()}
            disabled={!newLink.platform || (!newLink.url && !newLink.phoneNumber) || (isPlatformWithPhone && !newLink.phoneNumber) || addLink.isPending}
          >
            {addLink.isPending ? (
              <Loader2 className="w-4 h-4 animate-spin mr-2" />
            ) : null}
            Add Link
          </Button>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow">
        <h2 className="text-xl font-semibold p-6 border-b">Manage Social Media Links</h2>
        <div className="divide-y">
          {socialLinks?.map((link) => (
            <div key={link.id} className="p-6 flex items-center justify-between">
              <div>
                <h3 className="font-medium">{link.platform}</h3>
                {link.phone_number && (
                  <p className="text-sm text-gray-500">{link.phone_number}</p>
                )}
                {link.url && (
                  <a href={link.url} target="_blank" rel="noopener noreferrer" className="text-sm text-blue-500">
                    {link.url}
                  </a>
                )}
              </div>
              <div className="flex items-center gap-4">
                <div className="flex items-center gap-2">
                  <Switch
                    checked={link.is_active}
                    onCheckedChange={(checked) => updateStatus.mutate({ id: link.id, is_active: checked })}
                  />
                  <span className="text-sm text-gray-500">
                    {link.is_active ? "Active" : "Inactive"}
                  </span>
                </div>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => deleteLink.mutate(link.id)}
                >
                  <Trash2 className="w-4 h-4 text-red-500" />
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};