import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ContentManager } from "./ContentManager";
import { MessageViewer } from "./MessageViewer";
import { useAuth } from "@/contexts/AuthContext";
import { Button } from "./ui/button";
import { supabase } from "@/lib/supabase";
import { PackageManager } from "./PackageManager";
import { HeroManager } from "./HeroManager";
import { ServicesManager } from "./ServicesManager";
import { AboutManager } from "./AboutManager";
import { CompanyManager } from "./CompanyManager";
import { SocialMediaManager } from "./SocialMediaManager";
import { EmailSettingsManager } from "./EmailSettingsManager";
import ReviewsManager from "./ReviewsManager";
import { WhyChooseUsManager } from "./WhyChooseUsManager";
import { NotFound } from "./NotFound";

export const AdminDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState(() => {
    const path = location.pathname.split('/').pop();
    return path && ['content', 'hero', 'packages', 'services', 'about', 'company', 'social', 'messages', 'email', 'reviews', 'why-choose-us'].includes(path)
      ? path
      : 'content';
  });

  useEffect(() => {
    const path = location.pathname.split('/').pop();
    if (path && ['content', 'hero', 'packages', 'services', 'about', 'company', 'social', 'messages', 'email', 'reviews', 'why-choose-us'].includes(path)) {
      setActiveTab(path);
    }
  }, [location.pathname]);

  const handleSignOut = async () => {
    await supabase.auth.signOut();
    navigate("/admin/login");
  };

  const handleTabChange = (value: string) => {
    setActiveTab(value);
    navigate(`/admin/${value}`);
  };

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold">Admin Dashboard</h1>
          <div className="flex items-center gap-4">
            <span className="text-gray-600">{user?.email}</span>
            <Button variant="outline" onClick={handleSignOut}>
              Sign Out
            </Button>
          </div>
        </div>

        <Tabs value={activeTab} onValueChange={handleTabChange}>
          <TabsList className="mb-8">
            <TabsTrigger value="content">General Content</TabsTrigger>
            <TabsTrigger value="hero">Hero Images</TabsTrigger>
            <TabsTrigger value="packages">Packages</TabsTrigger>
            <TabsTrigger value="services">Services</TabsTrigger>
            <TabsTrigger value="about">About Us</TabsTrigger>
            <TabsTrigger value="company">Company Info</TabsTrigger>
            <TabsTrigger value="social">Social Media</TabsTrigger>
            <TabsTrigger value="messages">Messages</TabsTrigger>
            <TabsTrigger value="email">Email Settings</TabsTrigger>
            <TabsTrigger value="reviews">Reviews</TabsTrigger>
            <TabsTrigger value="why-choose-us">Why Choose Us</TabsTrigger>
          </TabsList>

          <Routes>
            <Route path="/" element={<Navigate to="content" replace />} />
            <Route path="content" element={<ContentManager />} />
            <Route path="hero" element={<HeroManager />} />
            <Route path="packages" element={<PackageManager />} />
            <Route path="services" element={<ServicesManager />} />
            <Route path="about" element={<AboutManager />} />
            <Route path="company" element={<CompanyManager />} />
            <Route path="social" element={<SocialMediaManager />} />
            <Route path="messages" element={<MessageViewer />} />
            <Route path="email" element={<EmailSettingsManager />} />
            <Route path="reviews" element={<ReviewsManager />} />
            <Route path="why-choose-us" element={<WhyChooseUsManager />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Tabs>
      </div>
    </div>
  );
};