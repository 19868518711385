import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Checkbox } from "@/components/ui/checkbox";

interface QuoteRequest {
  id: number;
  name: string;
  email: string;
  phone: string;
  message: string;
  package_name: string;
  created_at: string;
  is_attended: boolean;
}

interface QuoteRequestsTableProps {
  requests: QuoteRequest[];
  onToggleAttended: (id: number, isAttended: boolean) => void;
}

export const QuoteRequestsTable = ({ requests, onToggleAttended }: QuoteRequestsTableProps) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Date</TableHead>
          <TableHead>Name</TableHead>
          <TableHead>Email</TableHead>
          <TableHead>Phone</TableHead>
          <TableHead>Package</TableHead>
          <TableHead>Message</TableHead>
          <TableHead>Status</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {requests.map((quote) => (
          <TableRow key={quote.id}>
            <TableCell>
              {new Date(quote.created_at).toLocaleDateString()}
            </TableCell>
            <TableCell>{quote.name}</TableCell>
            <TableCell>{quote.email}</TableCell>
            <TableCell>{quote.phone}</TableCell>
            <TableCell>{quote.package_name}</TableCell>
            <TableCell>{quote.message}</TableCell>
            <TableCell>
              <div className="flex items-center space-x-2">
                <Checkbox
                  checked={quote.is_attended}
                  onCheckedChange={(checked) => 
                    onToggleAttended(quote.id, checked as boolean)
                  }
                />
                <span>{quote.is_attended ? 'Attended' : 'Pending'}</span>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};