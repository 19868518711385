import { useQuery } from "@tanstack/react-query";
import { Award, Heart, Shield, Star, Globe, Phone, Compass, Users, Trophy, Target } from "lucide-react";
import { useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";
import { supabase } from "@/lib/supabase";

interface WhyChooseUsItem {
  id: number;
  title: string;
  description: string;
  icon: string;
  image_url: string | null;
}

const iconMap = {
  shield: <Shield className="w-12 h-12 animate-icon-bounce" />,
  heart: <Heart className="w-12 h-12 animate-icon-pulse" />,
  award: <Award className="w-12 h-12 animate-icon-spin" />,
  star: <Star className="w-12 h-12 animate-icon-bounce" />,
  target: <Target className="w-12 h-12 animate-icon-pulse" />,
  trophy: <Trophy className="w-12 h-12 animate-icon-bounce" />,
  users: <Users className="w-12 h-12 animate-icon-pulse" />,
  compass: <Compass className="w-12 h-12 animate-icon-spin" />,
  globe: <Globe className="w-12 h-12 animate-icon-bounce" />,
  phone: <Phone className="w-12 h-12 animate-icon-pulse" />,
};

const WhyChooseUs = () => {
  const [selectedItem, setSelectedItem] = useState<WhyChooseUsItem | null>(null);

  const { data: reasons = [], isLoading } = useQuery({
    queryKey: ["whyChooseUs"],
    queryFn: async () => {
      console.log("Fetching why choose us items...");
      const { data, error } = await supabase
        .from("why_choose_us")
        .select("*")
        .order("id", { ascending: true });

      if (error) {
        console.error("Error fetching why choose us items:", error);
        throw error;
      }

      console.log("Successfully fetched why choose us items:", data);
      return data as WhyChooseUsItem[];
    },
  });

  const formatDescription = (description: string) => {
    return description.split('\n').map((line, index) => {
      if (line.startsWith('# ')) {
        return <h3 key={index} className="text-xl font-bold mb-3">{line.slice(2)}</h3>;
      } else if (line.startsWith('* ')) {
        return <li key={index} className="ml-4">{line.slice(2)}</li>;
      } else if (line.startsWith('**') && line.endsWith('**')) {
        return <p key={index} className="font-bold">{line.slice(2, -2)}</p>;
      } else if (line.startsWith('_') && line.endsWith('_')) {
        return <p key={index} className="italic">{line.slice(1, -1)}</p>;
      }
      return <p key={index} className="mb-2">{line}</p>;
    });
  };

  if (isLoading) {
    return (
      <section className="py-20 bg-shell-light">
        <div className="container">
          <h2 className="text-4xl font-bold text-center mb-16 text-shell-dark">
            Why Choose Us
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[1, 2, 3, 4].map((i) => (
              <div
                key={i}
                className="bg-white rounded-lg shadow-lg p-8 animate-pulse"
              >
                <div className="w-12 h-12 bg-gray-200 rounded-full mb-6 mx-auto" />
                <div className="h-6 bg-gray-200 rounded mb-4" />
                <div className="h-4 bg-gray-200 rounded mb-2" />
                <div className="h-4 bg-gray-200 rounded mb-2 w-3/4" />
                <div className="h-4 bg-gray-200 rounded w-1/2" />
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="py-20 bg-shell-light relative">
      <div className="container">
        <h2 className="text-4xl font-bold text-center mb-16 text-shell-dark animate-fade-up">
          Why Choose Us
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {reasons.map((reason, index) => (
            <div
              key={reason.id}
              className="bg-white rounded-lg shadow-lg transform hover:-translate-y-2 transition-all duration-300 animate-fade-up cursor-pointer"
              style={{ animationDelay: `${index * 150}ms` }}
              onClick={() => setSelectedItem(reason)}
            >
              <div className="p-8 flex flex-col items-center text-center">
                <div className="text-shell-primary mb-6 animate-float">
                  {iconMap[reason.icon as keyof typeof iconMap]}
                </div>
                <h3 className="text-xl font-semibold mb-4 text-shell-dark">
                  {reason.title}
                </h3>
                <p className="text-gray-600 line-clamp-3">{reason.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Dialog open={!!selectedItem} onOpenChange={() => setSelectedItem(null)}>
        <DialogContent className="bg-white max-w-3xl max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle className="text-2xl font-bold text-shell-dark">
              {selectedItem?.title}
            </DialogTitle>
          </DialogHeader>
          {selectedItem?.image_url && (
            <div className="relative w-full h-64 mb-6">
              <img
                src={selectedItem.image_url}
                alt={selectedItem.title}
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
          )}
          <div className="prose prose-lg max-w-none">
            {selectedItem && formatDescription(selectedItem.description)}
          </div>
        </DialogContent>
      </Dialog>
    </section>
  );
};

export default WhyChooseUs;
