import { Suspense } from "react";
import Hero from "@/components/Hero";
import WhyChooseUs from "@/components/WhyChooseUs";
import Services from "@/components/Services";
import About from "@/components/About";
import Packages from "@/components/Packages";
import Contact from "@/components/Contact";
import { Footer } from "@/components/Footer";
import { Loader2 } from "lucide-react";
import { Reviews } from "@/components/Reviews";

const LoadingSpinner = () => (
  <div className="min-h-screen flex items-center justify-center bg-background">
    <Loader2 className="w-8 h-8 animate-spin text-primary" />
  </div>
);

const Index = () => {
  console.log('Index component rendering');
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <main className="min-h-screen bg-background">
        <Hero />
        <Services />
        <div className="relative z-10 bg-travel-light">
          <Packages />
        </div>
        <About />
        <WhyChooseUs />
        <Reviews />
        <Contact />
        <Footer />
      </main>
    </Suspense>
  );
};

export default Index;