import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import { useToast } from "@/hooks/use-toast";
import { Loader2 } from "lucide-react";

export const ContentManager = () => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [uploading, setUploading] = useState(false);

  const { data: siteContent, isLoading } = useQuery({
    queryKey: ["site-content"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("site_content")
        .select("*");
      if (error) throw error;
      return data.reduce((acc, item) => ({
        ...acc,
        [item.section]: item.content
      }), {});
    }
  });

  const updateContent = useMutation({
    mutationFn: async ({ section, content }: { section: string; content: any }) => {
      const { error } = await supabase
        .from("site_content")
        .upsert({ section, content });
      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["site-content"] });
      toast({
        title: "Success",
        description: "Content updated successfully",
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to update content: " + error.message,
        variant: "destructive",
      });
    },
  });

  const handleLogoUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setUploading(true);
      const file = event.target.files?.[0];
      if (!file) return;

      const fileExt = file.name.split(".").pop();
      const fileName = `logo.${fileExt}`;

      const { error: uploadError } = await supabase.storage
        .from("hero-images")
        .upload(fileName, file, { upsert: true });

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from("hero-images")
        .getPublicUrl(fileName);

      await updateContent.mutateAsync({
        section: "logo",
        content: { url: publicUrl }
      });

      toast({
        title: "Success",
        description: "Logo updated successfully",
      });
    } catch (error: any) {
      toast({
        title: "Error",
        description: "Failed to upload logo: " + error.message,
        variant: "destructive",
      });
    } finally {
      setUploading(false);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="space-y-8">
      <div className="p-6 bg-white rounded-lg shadow">
        <h2 className="text-xl font-semibold mb-4">Logo</h2>
        <div className="flex items-center gap-4">
          {siteContent?.logo?.url && (
            <img
              src={siteContent.logo.url}
              alt="Current logo"
              className="w-32 h-32 object-contain"
            />
          )}
          <Input
            type="file"
            accept="image/*"
            onChange={handleLogoUpload}
            disabled={uploading}
          />
        </div>
      </div>

      <div className="p-6 bg-white rounded-lg shadow">
        <h2 className="text-xl font-semibold mb-4">Hero Section</h2>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-2">Title</label>
            <Input
              value={siteContent?.hero?.title || ""}
              onChange={(e) =>
                updateContent.mutate({
                  section: "hero",
                  content: {
                    ...siteContent?.hero,
                    title: e.target.value,
                  },
                })
              }
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-2">Subtitle</label>
            <Input
              value={siteContent?.hero?.subtitle || ""}
              onChange={(e) =>
                updateContent.mutate({
                  section: "hero",
                  content: {
                    ...siteContent?.hero,
                    subtitle: e.target.value,
                  },
                })
              }
            />
          </div>
        </div>
      </div>

      <Button
        disabled={updateContent.isPending}
        onClick={() => queryClient.invalidateQueries({ queryKey: ["site-content"] })}
      >
        {updateContent.isPending ? (
          <Loader2 className="w-4 h-4 animate-spin mr-2" />
        ) : null}
        Refresh Content
      </Button>
    </div>
  );
};