import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { useToast } from "@/hooks/use-toast";
import { Loader2, Plus, Trash2 } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";

export const CompanyManager = () => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [uploading, setUploading] = useState(false);

  const { data: companyInfo, isLoading: isLoadingInfo } = useQuery({
    queryKey: ["company-info"],
    queryFn: async () => {
      console.log("Fetching company info...");
      const { data, error } = await supabase
        .from("company_info")
        .select("*")
        .single();
      if (error) throw error;
      return data;
    }
  });

  const { data: addresses, isLoading: isLoadingAddresses } = useQuery({
    queryKey: ["company-addresses"],
    queryFn: async () => {
      console.log("Fetching company addresses...");
      const { data, error } = await supabase
        .from("company_addresses")
        .select("*")
        .order('is_primary', { ascending: false });
      if (error) throw error;
      return data;
    }
  });

  const updateCompanyInfo = useMutation({
    mutationFn: async (info: typeof companyInfo) => {
      console.log("Updating company info:", info);
      const { error } = await supabase
        .from("company_info")
        .upsert(info);
      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["company-info"] });
      toast({
        title: "Success",
        description: "Company information updated successfully",
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to update company information: " + error.message,
        variant: "destructive",
      });
    },
  });

  const updateAddress = useMutation({
    mutationFn: async (address: any) => {
      console.log("Updating address:", address);
      const { error } = await supabase
        .from("company_addresses")
        .upsert(address);
      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["company-addresses"] });
      toast({
        title: "Success",
        description: "Address updated successfully",
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to update address: " + error.message,
        variant: "destructive",
      });
    },
  });

  const deleteAddress = useMutation({
    mutationFn: async (id: number) => {
      console.log("Deleting address:", id);
      const { error } = await supabase
        .from("company_addresses")
        .delete()
        .eq('id', id);
      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["company-addresses"] });
      toast({
        title: "Success",
        description: "Address deleted successfully",
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to delete address: " + error.message,
        variant: "destructive",
      });
    },
  });

  const handleLogoUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setUploading(true);
      const file = event.target.files?.[0];
      if (!file) return;

      const fileExt = file.name.split(".").pop();
      const fileName = `logo.${fileExt}`;

      const { error: uploadError } = await supabase.storage
        .from("hero-images")
        .upload(fileName, file, { upsert: true });

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from("hero-images")
        .getPublicUrl(fileName);

      await updateCompanyInfo.mutateAsync({
        ...companyInfo,
        logo_url: publicUrl
      });

      toast({
        title: "Success",
        description: "Logo updated successfully",
      });
    } catch (error: any) {
      toast({
        title: "Error",
        description: "Failed to upload logo: " + error.message,
        variant: "destructive",
      });
    } finally {
      setUploading(false);
    }
  };

  const addNewAddress = () => {
    updateAddress.mutate({
      street: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
      is_primary: addresses?.length === 0
    });
  };

  if (isLoadingInfo || isLoadingAddresses) {
    return <div className="p-6 flex justify-center"><Loader2 className="animate-spin" /></div>;
  }

  return (
    <div className="space-y-8">
      <Card>
        <CardHeader>
          <CardTitle>Company Information</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="logo">Logo</Label>
            <div className="flex items-center gap-4">
              {companyInfo?.logo_url && (
                <img
                  src={companyInfo.logo_url}
                  alt="Company logo"
                  className="w-32 h-32 object-contain"
                />
              )}
              <Input
                id="logo"
                type="file"
                accept="image/*"
                onChange={handleLogoUpload}
                disabled={uploading}
              />
            </div>
          </div>

          <div className="space-y-2">
            <Label htmlFor="name">Company Name</Label>
            <Input
              id="name"
              value={companyInfo?.name || ""}
              onChange={(e) => updateCompanyInfo.mutate({
                ...companyInfo,
                name: e.target.value
              })}
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="slogan">Slogan</Label>
            <Input
              id="slogan"
              value={companyInfo?.slogan || ""}
              onChange={(e) => updateCompanyInfo.mutate({
                ...companyInfo,
                slogan: e.target.value
              })}
            />
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>Company Addresses</CardTitle>
          <Button onClick={addNewAddress} size="sm">
            <Plus className="w-4 h-4 mr-2" />
            Add Address
          </Button>
        </CardHeader>
        <CardContent>
          <div className="grid gap-4">
            {addresses?.map((address: any) => (
              <Card key={address.id}>
                <CardContent className="pt-6">
                  <div className="grid gap-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="space-y-2">
                        <Label htmlFor={`street-${address.id}`}>Street</Label>
                        <Input
                          id={`street-${address.id}`}
                          value={address.street}
                          onChange={(e) => updateAddress.mutate({
                            ...address,
                            street: e.target.value
                          })}
                        />
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor={`city-${address.id}`}>City</Label>
                        <Input
                          id={`city-${address.id}`}
                          value={address.city}
                          onChange={(e) => updateAddress.mutate({
                            ...address,
                            city: e.target.value
                          })}
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                      <div className="space-y-2">
                        <Label htmlFor={`state-${address.id}`}>State</Label>
                        <Input
                          id={`state-${address.id}`}
                          value={address.state}
                          onChange={(e) => updateAddress.mutate({
                            ...address,
                            state: e.target.value
                          })}
                        />
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor={`postal-${address.id}`}>Postal Code</Label>
                        <Input
                          id={`postal-${address.id}`}
                          value={address.postal_code}
                          onChange={(e) => updateAddress.mutate({
                            ...address,
                            postal_code: e.target.value
                          })}
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                      <div className="space-y-2">
                        <Label htmlFor={`country-${address.id}`}>Country</Label>
                        <Input
                          id={`country-${address.id}`}
                          value={address.country}
                          onChange={(e) => updateAddress.mutate({
                            ...address,
                            country: e.target.value
                          })}
                        />
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor={`phone-${address.id}`}>Phone</Label>
                        <Input
                          id={`phone-${address.id}`}
                          value={address.phone || ""}
                          onChange={(e) => updateAddress.mutate({
                            ...address,
                            phone: e.target.value
                          })}
                        />
                      </div>
                    </div>

                    <div className="space-y-2">
                      <Label htmlFor={`email-${address.id}`}>Email</Label>
                      <Input
                        id={`email-${address.id}`}
                        value={address.email || ""}
                        onChange={(e) => updateAddress.mutate({
                          ...address,
                          email: e.target.value
                        })}
                      />
                    </div>

                    <div className="space-y-2">
                      <Label htmlFor={`maps-url-${address.id}`}>Google Maps URL</Label>
                      <Input
                        id={`maps-url-${address.id}`}
                        value={address.google_maps_url || ""}
                        onChange={(e) => updateAddress.mutate({
                          ...address,
                          google_maps_url: e.target.value
                        })}
                        placeholder="Paste Google Maps embed URL here"
                      />
                    </div>

                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          id={`primary-${address.id}`}
                          checked={address.is_primary}
                          onChange={(e) => updateAddress.mutate({
                            ...address,
                            is_primary: e.target.checked
                          })}
                          className="rounded border-gray-300"
                        />
                        <Label htmlFor={`primary-${address.id}`}>Primary Address</Label>
                      </div>
                      <Button
                        variant="destructive"
                        size="sm"
                        onClick={() => deleteAddress.mutate(address.id)}
                      >
                        <Trash2 className="w-4 h-4 mr-2" />
                        Delete
                      </Button>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default CompanyManager;
