import { Phone, Facebook, Twitter, Instagram, Youtube, Linkedin } from "lucide-react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";

interface SocialMediaLinksProps {
  iconColor?: string;
  iconSize?: number;
  showPhoneNumbers?: boolean;
}

export const SocialMediaLinks = ({ 
  iconColor = "currentColor", 
  iconSize = 24,
  showPhoneNumbers = false 
}: SocialMediaLinksProps) => {
  const { data: links } = useQuery({
    queryKey: ["social-media-links"],
    queryFn: async () => {
      console.log("Fetching social media links...");
      const { data, error } = await supabase
        .from("social_media_links")
        .select("*")
        .eq("is_active", true);
      
      if (error) {
        console.error("Error fetching social media links:", error);
        throw error;
      }
      
      console.log("Social media links fetched:", data);
      return data || [];
    },
  });

  if (!links?.length) return null;

  // Function to get the appropriate icon component
  const getIconComponent = (platform: string) => {
    switch (platform.toLowerCase()) {
      case 'facebook':
        return Facebook;
      case 'x':
      case 'twitter':
        return Twitter;
      case 'instagram':
        return Instagram;
      case 'youtube':
        return Youtube;
      case 'linkedin':
        return Linkedin;
      default:
        return null;
    }
  };

  return (
    <div className="flex items-center gap-4">
      {links.map((link) => {
        if (link.platform_type === 'phone' && !showPhoneNumbers) {
          return null;
        }

        // Special handling for phone numbers
        if (link.platform_type === 'phone') {
          return (
            <a
              key={link.id}
              href={`tel:${link.phone_number}`}
              className="flex items-center gap-2 text-white hover:text-white/80 transition-colors"
            >
              <Phone size={iconSize} className="text-white" />
              <span className="text-white">{link.phone_number}</span>
            </a>
          );
        }

        const IconComponent = getIconComponent(link.platform);
        if (!IconComponent) return null;

        // Determine icon color based on platform
        const platformIconColor = link.platform.toLowerCase() === 'x' ? '#000000' : iconColor;

        return (
          <a
            key={link.id}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            className="hover:opacity-80 transition-opacity"
          >
            <IconComponent 
              size={iconSize} 
              color={platformIconColor}
            />
          </a>
        );
      })}
    </div>
  );
};