import { useState, useEffect } from "react";
import { Plane } from "lucide-react";
import { cn } from "@/lib/utils";
import { supabase } from "@/lib/supabase";
import { HeroHeader } from "./hero/HeroHeader";
import { HeroContent } from "./hero/HeroContent";
import { HeroNavigation } from "./hero/HeroNavigation";
import { HeroExploreButton } from "./hero/HeroExploreButton";
import { CompanyInfo, HeroImage } from "./hero/types";

const Hero = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [images, setImages] = useState<HeroImage[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [companyInfo, setCompanyInfo] = useState<CompanyInfo | null>(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    console.log("Hero component mounted");
    fetchImages();
    fetchCompanyInfo();
    
    const channel = supabase
      .channel('hero-images-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'hero_images'
        },
        (payload) => {
          console.log('Hero images changed:', payload);
          fetchImages();
        }
      )
      .subscribe();

    const companyInfoChannel = supabase
      .channel('company-info-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'company_info'
        },
        (payload) => {
          console.log('Company info changed:', payload);
          fetchCompanyInfo();
        }
      )
      .subscribe();

    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % images.length);
    }, 5000);

    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    
    return () => {
      clearInterval(interval);
      window.removeEventListener('scroll', handleScroll);
      supabase.removeChannel(channel);
      supabase.removeChannel(companyInfoChannel);
    };
  }, [images.length]);

  const fetchImages = async () => {
    try {
      console.log("Fetching hero images...");
      const { data, error } = await supabase
        .from('hero_images')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) {
        console.error("Error fetching hero images:", error);
        throw error;
      }
      
      console.log("Hero images fetched successfully:", data);
      setImages(data || []);
    } catch (error) {
      console.error("Error fetching hero images:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCompanyInfo = async () => {
    try {
      console.log("Fetching company info...");
      const { data, error } = await supabase
        .from('company_info')
        .select('*')
        .maybeSingle();

      if (error) {
        console.error("Error fetching company info:", error);
        throw error;
      }
      
      console.log("Company info fetched successfully:", data);
      setCompanyInfo(data);
    } catch (error) {
      console.error("Error fetching company info:", error);
    }
  };

  const nextImage = () => {
    setCurrentImage((prev) => (prev + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage((prev) => (prev - 1 + images.length) % images.length);
  };

  const scrollToContent = () => {
    const contentElement = document.getElementById('about-section');
    if (contentElement) {
      contentElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const isScrolled = scrollPosition > 100;

  if (isLoading || images.length === 0) {
    return (
      <div className="h-screen bg-travel-primary/10 animate-pulse flex items-center justify-center">
        <Plane className="w-16 h-16 text-travel-primary animate-bounce" />
      </div>
    );
  }

  return (
    <div className="relative h-screen">
      {images.map((image, index) => (
        <div
          key={image.id}
          className={cn(
            "absolute inset-0 transition-opacity duration-1000",
            index === currentImage ? "opacity-100" : "opacity-0"
          )}
        >
          <img
            src={image.image_url}
            alt={image.alt_text}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/30 to-transparent" />
        </div>
      ))}
      
      <HeroHeader companyInfo={companyInfo} isScrolled={isScrolled} />
      <HeroContent companyInfo={companyInfo} isScrolled={isScrolled} />
      <HeroExploreButton onExplore={scrollToContent} />
      <HeroNavigation
        currentImage={currentImage}
        totalImages={images.length}
        onPrevious={prevImage}
        onNext={nextImage}
        onSelect={setCurrentImage}
      />
    </div>
  );
};

export default Hero;